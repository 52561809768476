*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%;
  box-sizing: border-box; }
  @media only screen and (max-width: 68.75em) {
    html {
      font-size: 50%; } }
  @media only screen and (max-width: 56.25em) {
    html {
      font-size: 40%; } }
  @media only screen and (max-width: 50em) {
    html {
      font-size: 38%; } }

body {
  font-family: Open Sans;
  font-weight: 400;
  color: #111111;
  line-height: 1.6;
  margin: 0 auto;
  max-width: 1440px;
  background-color: #6C7F8E; }

.logo {
  display: grid;
  justify-items: start; }
  @media only screen and (max-width: 56.25em) {
    .logo {
      justify-items: center; } }
  @media only screen and (max-width: 43.75em) {
    .logo {
      justify-items: start; } }
  .logo a:link,
  .logo a:active,
  .logo a:visited {
    outline: none; }
  .logo__image {
    height: 16rem;
    display: block;
    object-fit: cover; }

.nav {
  list-style: none;
  padding-left: 10rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, max-content));
  justify-items: center; }
  @media only screen and (max-width: 75em) {
    .nav {
      padding-left: 6rem; } }
  @media only screen and (max-width: 56.25em) {
    .nav {
      padding-left: 0;
      grid-template-columns: repeat(auto-fit, minmax(25%, max-content));
      padding: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .nav {
      display: none; } }
  .nav__link:link, .nav__link:visited {
    font-size: 1.4rem;
    font-family: Open Sans;
    color: #6D6E71;
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 1.5rem;
    background-color: white;
    padding: 1.5rem; }
  .nav__link:hover {
    text-decoration: underline;
    text-decoration-color: #CBCBCD;
    text-decoration-style: solid;
    text-decoration-thickness: 2px; }

.heading-1 {
  font-family: Open Sans;
  font-size: 6rem;
  font-weight: 700;
  line-height: 1.4; }
  @media only screen and (max-width: 37.5em) {
    .heading-1 {
      font-size: 4rem; } }

.heading-2 {
  font-family: Open Sans;
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.4; }

.heading-3 {
  font-family: Open Sans;
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.4; }

.heading-4 {
  font-family: Open Sans;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 1.4; }

.heading-5 {
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4; }
  @media only screen and (max-width: 81.25em) {
    .heading-5 {
      font-size: 1.5rem; } }

.heading-6 {
  font-family: Open Sans;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.4; }

.heading-6a {
  font-family: Libre Baskerville, serif;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.4; }

.heading-6b {
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.4; }

.regular1 {
  font-family: Open Sans;
  font-size: 2.1rem;
  font-weight: 400;
  line-height: 1.4; }

.heading-blue {
  color: #1B75BC; }

.regular2 {
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.4; }

.regular3 {
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4; }

.regular4 {
  font-family: Open Sans;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.4; }

.heading-blog {
  font-family: Open Sans;
  font-size: 3.6rem;
  font-weight: 700; }

.button {
  border-radius: 50px;
  font-family: Open Sans;
  font-size: 1.4rem;
  font-weight: 700;
  color: #FFFFFF;
  border: none;
  cursor: pointer;
  outline: none; }
  .button__large {
    width: 33rem;
    height: 4.5rem;
    font-weight: 300; }
  .button__small {
    width: 20rem;
    height: 4.5rem; }
  .button__blue {
    background-color: #1B75BC; }
  .button__white {
    background-color: #FFFFFF;
    color: #1B75BC;
    font-weight: 300;
    border: 1px solid #1B75BC; }

.button-custom {
  width: 20.2rem;
  height: 6rem;
  border: 3px solid #1B75BC;
  border-radius: 5px;
  background-color: #d2e8f9;
  color: #1B75BC;
  font-size: 1.8rem;
  cursor: pointer;
  outline: none; }

.consultation {
  width: 31.2rem;
  height: 4.2rem;
  border: none;
  border-radius: 20px;
  background-color: #1B75BC;
  color: #FFFFFF;
  font-size: 1.4rem;
  cursor: pointer;
  outline: none;
  font-weight: 300; }

.margin-right-small {
  margin-right: 1rem; }

.margin-bottom-small {
  margin-bottom: 2rem; }

.margin-bottom-extrasmall {
  margin-bottom: 1rem; }

.margin-bottom-medium {
  margin-bottom: 4rem; }

.margin-bottom-exlarge {
  margin-bottom: 15rem; }

.margin-top-small {
  margin-top: 2rem; }

.valogo {
  display: grid;
  justify-items: start; }
  .valogo a:link,
  .valogo a:active,
  .valogo a:visited {
    outline: none; }
  .valogo__image {
    height: 8.5rem;
    height: 8rem;
    display: block;
    object-fit: cover; }

.vanav {
  list-style: none;
  padding-left: 20rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, max-content)); }
  .vanav__link:link, .vanav__link:visited {
    font-size: 1.6rem;
    font-family: Open Sans;
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    text-align: center;
    padding: 1.5rem;
    background-color: transparent; }
  .vanav__link:hover {
    text-decoration: underline;
    text-decoration-color: #CBCBCD;
    text-decoration-style: solid;
    text-decoration-thickness: 2px; }

.maincontainer {
  display: grid;
  grid-template-rows: max-content auto 47.2rem repeat(7, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }
  @media only screen and (max-width: 81.25em) {
    .maincontainer {
      grid-template-rows: max-content auto 47.2rem repeat(7, auto);
      grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; } }
  @media only screen and (max-width: 62.5em) {
    .maincontainer {
      grid-template-rows: max-content auto 47.2rem repeat(7, auto);
      grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; } }
  @media only screen and (max-width: 43.75em) {
    .maincontainer {
      grid-template-rows: max-content auto repeat(8, auto);
      grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; } }

.logincontainer {
  display: grid;
  grid-template-rows: 70vh 30vh;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end];
  background-color: #FFFFFF;
  background-image: url("/img/login_background.png");
  background-repeat: no-repeat;
  background-size: contain; }

.signupcontainer {
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end];
  background-color: #F5F5FC;
  background-image: url("/img/login_background.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0 60%; }

.aboutcontainer {
  display: grid;
  grid-template-rows: max-content 7, auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.blogcontainer {
  display: grid;
  grid-template-rows: max-content auto auto max-content repeat(5, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.vacontainer {
  display: grid;
  grid-template-rows: 49rem auto auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.contactcontainer {
  display: grid;
  grid-template-rows: max-content 3, auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.messagecontainer {
  display: grid;
  grid-template-rows: 20.7rem 78vh auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.messageframecontainer {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 1fr; }

.vaprofilecontainer {
  display: grid;
  grid-template-rows: 20.7rem repeat(2, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.vaprogresscontainer {
  display: grid;
  grid-template-rows: 20.7rem 100vh auto;
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.allblogcontainer {
  display: grid;
  grid-template-rows: max-content auto max-content repeat(2, auto);
  grid-template-columns: [full-start] 1fr [center-start] repeat(12, minmax(min-content, 14rem)) [center-end] 1fr [full-end]; }

.systemcontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.admincontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(5, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.uploadcontainer {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-template-columns: [fullstart] auto [centerstart] repeat(10, minmax(min-content, 14.4rem)) [centerend] auto [fullend]; }

.header {
  background-color: white;
  grid-column: center-start / center-end;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: 40% minmax(40rem, 1fr);
  align-items: center; }
  @media only screen and (max-width: 56.25em) {
    .header {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, max-content);
      padding-top: 4rem; } }

.systemheader {
  grid-column: fullstart / fullend;
  grid-row: 1 / 2;
  border-bottom: 1px solid #F7941D; }
  .systemheader__logo {
    display: block;
    position: relative;
    margin: 0 auto;
    width: 25rem; }

.addblogs {
  grid-column: fullstart / fullend;
  grid-row: 4 / 5;
  position: relative;
  margin: 0 auto;
  width: 90%;
  padding: 2% 5%;
  margin-bottom: 2%;
  border: 1px solid #6C7F8E;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 100%;
  justify-items: center; }
  .addblogs__content {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    width: 80%; }
  .addblogs__label {
    font-family: Open Sans;
    font-size: 2.4rem;
    font-weight: 300; }
  .addblogs__input {
    width: 57rem;
    height: 4.7rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    outline: none; }
  .addblogs__excerpt {
    width: 57rem;
    height: 6.7rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    resize: none;
    outline: none; }
  .addblogs__main {
    width: 100rem;
    height: 50rem;
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 2%;
    resize: none;
    outline: none; }
  .addblogs__btn {
    width: 14rem;
    height: 2.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    outline: none; }

.systemnav {
  grid-column: fullstart / fullend;
  grid-row: 2 / 3;
  margin: 0 auto;
  position: relative;
  padding: 2% 0;
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  justify-items: center;
  border-bottom: 1px solid #F7941D; }
  .systemnav__nav ul {
    list-style-type: none; }
  .systemnav__nav li {
    display: inline-block;
    font-family: Open Sans;
    font-size: 1.8rem;
    margin-right: 5rem; }
  .systemnav__nav a,
  .systemnav__nav a:active,
  .systemnav__nav a:visited {
    color: #F7941D;
    text-decoration: none;
    cursor: pointer; }

.systemblogs {
  grid-column: fullstart / fullend;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-items: center;
  position: relative;
  margin: 0 auto;
  padding: 1% 0; }
  .systemblogs__buttons a,
  .systemblogs__buttons a:active,
  .systemblogs__buttons a:visited {
    text-decoration: none;
    cursor: pointer; }
  .systemblogs__buttons:not(:last-child) {
    margin-right: 10%; }
  .systemblogs__btn {
    width: 27rem;
    height: 4.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer; }

.showblogs {
  grid-column: fullstart / fullend;
  grid-row: 4 / 5;
  position: relative;
  margin: 0 auto;
  width: 90%;
  padding: 1% 5%;
  margin-bottom: 2%;
  border: 1px solid #111111;
  overflow-y: scroll; }
  .showblogs__header {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
    align-items: center;
    margin-bottom: 2%;
    border: 1px solid #111111;
    width: 100%; }
    .showblogs__header-text {
      font-family: Open Sans;
      font-size: 1.5rem;
      font-weight: 600; }
  .showblogs__infotxt {
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300; }
  .showblogs__content {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    justify-items: center;
    margin-bottom: 2%; }
    .showblogs__content a,
    .showblogs__content a:active,
    .showblogs__content a:visited {
      color: #F7941D;
      text-decoration: none;
      cursor: pointer; }
  .showblogs__infobtn {
    width: 14rem;
    height: 2.7rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    background-color: #F7941D;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    outline: none; }

.systemlogin {
  grid-column: fullstart / fullend;
  grid-row: 2 / 3;
  padding: 10% 0;
  display: grid;
  grid-template-rows: repeat(5, auto);
  justify-items: center; }
  .systemlogin__info, .systemlogin__passinfo {
    display: grid;
    grid-template-columns: repeat(2, max-content); }
  .systemlogin__label {
    font-family: Open Sans;
    font-size: 2.3rem;
    font-weight: 300;
    margin-right: 10rem; }
  .systemlogin__input {
    width: 30rem;
    height: 4.7rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid #FF673E;
    font-family: Open Sans;
    font-size: 2rem;
    font-weight: 300;
    padding: 2%;
    margin-bottom: 10%; }
  .systemlogin__text {
    font-family: Open Sans;
    font-size: 1.8rem;
    font-weight: 300;
    margin-bottom: 5%; }
  .systemlogin__anchor {
    margin-bottom: 1%; }
    .systemlogin__anchor a,
    .systemlogin__anchor a:active,
    .systemlogin__anchor a:visited {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 300;
      margin-bottom: 5%;
      text-decoration: none;
      cursor: pointer; }
  .systemlogin__btn {
    width: 27rem;
    height: 4.7rem;
    border: none;
    background-color: #F7941D;
    cursor: pointer;
    outline: none;
    color: #FFFFFF;
    margin-bottom: 5%; }

.systemfooter {
  grid-column: fullstart / fullend;
  font-family: Open Sans;
  font-size: 1.8rem;
  font-weight: 300;
  position: relative;
  margin: 0 auto;
  padding-top: 5%; }

.transform {
  background-color: #d5e8f6;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  padding: 5%;
  display: grid;
  grid-template-rows: repeat(4, auto); }
  @media only screen and (max-width: 43.75em) {
    .transform {
      grid-row-gap: 4rem;
      padding: 5% 2%; } }
  .transform__row1 {
    grid-row: 1 / 2;
    justify-self: center; }
  .transform__row2 {
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .transform__row2 {
        grid-template-columns: auto;
        padding-left: 25%; } }
    @media only screen and (max-width: 31.25em) {
      .transform__row2 {
        grid-template-columns: auto;
        padding-left: 0; } }
    @media only screen and (max-width: 25em) {
      .transform__row2 {
        grid-template-columns: auto;
        padding-left: 0; } }
    .transform__row2-left {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
    .transform__row2-right {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
  .transform__row3 {
    grid-row: 3 / 4;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .transform__row3 {
        grid-template-columns: 1fr;
        padding-left: 25%; } }
    @media only screen and (max-width: 31.25em) {
      .transform__row3 {
        grid-template-columns: auto;
        padding-left: 0; } }
    @media only screen and (max-width: 25em) {
      .transform__row3 {
        grid-template-columns: auto;
        padding-left: 0; } }
    .transform__row3-left {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
    .transform__row3-right {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
  .transform__row4 {
    grid-row: 4 / 5;
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .transform__row4 {
        grid-template-columns: 1fr;
        padding-left: 25%; } }
    @media only screen and (max-width: 31.25em) {
      .transform__row4 {
        grid-template-columns: auto;
        padding-left: 0; } }
    @media only screen and (max-width: 25em) {
      .transform__row4 {
        grid-template-columns: auto;
        padding-left: 0; } }
    .transform__row4-left {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
      @media only screen and (max-width: 43.75em) {
        .transform__row4-left {
          grid-template-columns: repeat(2, max-content); } }
    .transform__row4-right {
      display: grid;
      grid-template-columns: repeat(2, max-content);
      grid-column-gap: 2rem;
      align-items: center; }
  .transform__icon {
    width: 5rem;
    height: 5rem; }
  @media only screen and (max-width: 81.25em) {
    .transform__fix {
      max-width: 80%; } }

.display {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-top: 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, max-content));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .display {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 87.5em) {
    .display {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .display {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      padding-top: 5%;
      justify-items: center; } }
  .display__text {
    display: grid;
    grid-template-rows: auto auto;
    padding-left: 13%; }
    @media only screen and (max-width: 43.75em) {
      .display__text {
        padding-left: 0%;
        padding-bottom: 5%; } }
  .display__subheader {
    font-size: 1.6rem;
    font-weight: 300;
    font-family: inherit; }
    @media only screen and (max-width: 43.75em) {
      .display__subheader {
        text-align: center;
        margin-bottom: 10%; } }
  .display__right a:link,
  .display__right a:visited {
    outline: none;
    text-decoration: none; }
  @media only screen and (max-width: 43.75em) {
    .display__right {
      display: grid;
      grid-template-rows: repeat(2, auto);
      justify-items: center; } }
  @media only screen and (max-width: 81.25em) {
    .display__button1 {
      width: 28rem;
      height: 4.5rem;
      font-weight: 300; } }
  @media only screen and (max-width: 43.75em) {
    .display__button1 {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 7%; } }
  @media only screen and (max-width: 81.25em) {
    .display__button2 {
      width: 15rem;
      height: 4.5rem; } }
  @media only screen and (max-width: 43.75em) {
    .display__button2 {
      display: block;
      margin-left: auto;
      margin-right: auto; } }
  .display__box {
    padding-right: 13%; }
    @media only screen and (max-width: 43.75em) {
      .display__box {
        padding-right: 0; } }
    .display__box img {
      width: 100%; }

.stories {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  display: grid;
  grid-template-rows: repeat(3, 1fr); }
  .stories__img {
    width: 100%;
    height: 100%;
    vertical-align: bottom; }
  .stories__row1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .stories__row1 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr); } }
    .stories__row1-right {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center; }
      @media only screen and (max-width: 43.75em) {
        .stories__row1-right {
          grid-template-columns: 1fr;
          grid-template-rows: max-content auto; } }
    .stories__row1-header {
      padding-right: 10%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row1-header {
          font-size: 3.6rem !important;
          text-align: center !important; } }
    .stories__row1-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Open Sans;
      position: relative;
      font-weight: 700;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0); }
      @media only screen and (max-width: 62.5em) {
        .stories__row1-num {
          padding-top: 12px; } }
      @media only screen and (max-width: 43.75em) {
        .stories__row1-num {
          margin-top: -3%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          transform: translate(0, 0); } }
      @media only screen and (max-width: 31.25em) {
        .stories__row1-num {
          margin-top: -5%; } }
      @media only screen and (max-width: 25em) {
        .stories__row1-num {
          margin-top: -7%; } }
    .stories__row1-text {
      text-align: center; }
    .stories__row1-body {
      text-align: justify !important;
      padding-left: 10%;
      padding-right: 18%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row1-body {
          font-size: 2.6rem !important;
          text-align: center !important;
          font-weight: 300 !important;
          padding-left: 10%;
          padding-right: 10%; } }
  .stories__row2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .stories__row2 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr); } }
    .stories__row2-header {
      padding-left: 10%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row2-header {
          font-size: 3.6rem !important;
          text-align: center !important; } }
    .stories__row2-left {
      background-color: #fef3e6;
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center; }
      @media only screen and (max-width: 43.75em) {
        .stories__row2-left {
          grid-row: 2 / 3;
          grid-template-columns: 1fr;
          grid-template-rows: max-content auto; } }
    .stories__row2-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Open Sans;
      position: relative;
      font-weight: 700;
      transform: translate(50%, 0); }
      @media only screen and (max-width: 62.5em) {
        .stories__row2-num {
          padding-top: 12px; } }
      @media only screen and (max-width: 43.75em) {
        .stories__row2-num {
          grid-row: 1 / 2;
          margin-top: -3%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          transform: translate(0, 0); } }
      @media only screen and (max-width: 31.25em) {
        .stories__row2-num {
          margin-top: -5%; } }
      @media only screen and (max-width: 25em) {
        .stories__row2-num {
          margin-top: -7%; } }
    .stories__row2-text {
      text-align: center; }
    .stories__row2-body {
      text-align: justify !important;
      padding-left: 18%;
      padding-right: 10%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row2-body {
          font-size: 2.6rem !important;
          text-align: center !important;
          font-weight: 300 !important;
          padding-left: 10%;
          padding-right: 10%; } }
  .stories__row3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
    @media only screen and (max-width: 43.75em) {
      .stories__row3 {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr); } }
    .stories__row3-right {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center; }
      @media only screen and (max-width: 43.75em) {
        .stories__row3-right {
          grid-template-columns: 1fr;
          grid-template-rows: max-content auto; } }
    .stories__row3-header {
      padding-right: 10%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row3-header {
          font-size: 3.6rem !important;
          text-align: center !important; } }
    .stories__row3-num {
      height: 9rem;
      width: 9rem;
      background-color: #AF7F43;
      border-radius: 0;
      display: block;
      text-align: center;
      color: #FFFFFF;
      font-size: 3.6rem;
      padding-top: 18px;
      text-anchor: middle;
      font-family: Open Sans;
      position: relative;
      font-weight: 700;
      transform: translate(-50%, 0);
      -webkit-transform: translate(-50%, 0); }
      @media only screen and (max-width: 62.5em) {
        .stories__row3-num {
          padding-top: 12px; } }
      @media only screen and (max-width: 43.75em) {
        .stories__row3-num {
          margin-top: -3%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          transform: translate(0, 0); } }
      @media only screen and (max-width: 31.25em) {
        .stories__row3-num {
          margin-top: -5%; } }
      @media only screen and (max-width: 25em) {
        .stories__row3-num {
          margin-top: -7%; } }
    .stories__row3-text {
      text-align: center; }
    .stories__row3-body {
      text-align: justify !important;
      padding-left: 10%;
      padding-right: 18%; }
      @media only screen and (max-width: 43.75em) {
        .stories__row3-body {
          font-size: 2.6rem !important;
          text-align: center !important;
          font-weight: 300 !important;
          padding-left: 10%;
          padding-right: 10%; } }

.services {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  padding: 5% 12%;
  display: grid;
  grid-template-columns: 1fr; }

.faq {
  background-color: #d2e8f9;
  grid-column: center-start / center-end;
  grid-row: 9 / 10;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 43.75em) {
    .faq {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto); } }
  .faq__section1 {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    padding: 13% 16%; }
    @media only screen and (max-width: 43.75em) {
      .faq__section1 {
        justify-items: center; } }
    .faq__section1-text {
      color: #1B75BC;
      font-size: 3.6rem;
      font-family: Open Sans; }
    .faq__section1-button {
      margin-top: 8rem;
      border-color: #1B75BC; }
  .faq__section2 {
    display: grid;
    grid-template-rows: repeat(5, min-content);
    align-self: center;
    justify-self: center; }
    .faq__section2-text {
      color: #3C3C3C;
      font-size: 1.8rem;
      font-family: inherit; }
      .faq__section2-text:not(:last-child) {
        margin-bottom: 2rem; }
      .faq__section2-text a,
      .faq__section2-text a:link,
      .faq__section2-text a:active,
      .faq__section2-text a:visited {
        outline: none;
        text-decoration: underline;
        color: #3C3C3C;
        cursor: pointer; }

.faqanswer {
  font-family: Open Sans;
  font-size: 1.5rem;
  padding-right: 4rem; }

.faqanswer:not(:last-child) {
  padding-bottom: 2rem; }

#answer1,
#answer2,
#answer3,
#answer4,
#answer5 {
  display: none; }

.footer {
  background-color: #6C7F8E;
  grid-column: center-start / center-end;
  display: grid;
  grid-template-columns: 40% 0 60%;
  padding-top: 4rem;
  margin-bottom: 10rem; }
  @media only screen and (max-width: 37.5em) {
    .footer {
      grid-template-columns: 1fr;
      margin-bottom: 4rem; } }
  .footer__contact {
    grid-column: 1 / 2;
    display: grid;
    padding-left: 5rem;
    grid-template-columns: 40% 1fr; }
    @media only screen and (max-width: 37.5em) {
      .footer__contact {
        grid-column: 1 / -1;
        padding-left: 0rem;
        grid-template-columns: repeat(2, auto);
        justify-items: center; } }
    .footer__contact a:link,
    .footer__contact a:active,
    .footer__contact a:visited {
      outline: none; }
    .footer__contact-logo {
      width: 12rem;
      border: 0; }
    .footer__contact--info {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto); }
      @media only screen and (max-width: 37.5em) {
        .footer__contact--info {
          padding-bottom: 5%; } }
      .footer__contact--info-header {
        color: #FFFFFF;
        font-size: 1.6rem;
        font-weight: 400;
        align-self: end; }
      .footer__contact--info-text {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF; }
      .footer__contact--info-phone {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF; }
  .footer__nav {
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto); }
    @media only screen and (max-width: 37.5em) {
      .footer__nav {
        grid-column: 1 / -1; } }
    .footer__nav-nav {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      justify-items: center;
      align-items: center;
      margin-bottom: 4rem; }
      .footer__nav-nav a:link,
      .footer__nav-nav a:visited {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600; }
    .footer__nav-copy {
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 400;
      padding-right: 6%;
      justify-self: end;
      align-self: center; }
      @media only screen and (max-width: 37.5em) {
        .footer__nav-copy {
          justify-self: center; } }

.promise {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  display: grid;
  grid-template-rows: repeat(2, 1fr); }
  @media only screen and (max-width: 43.75em) {
    .promise {
      grid-template-rows: repeat(2, auto); } }
  .promise__top {
    padding: 5% 12%; }
  .promise__bottom {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: max-content;
    grid-column-gap: 2rem;
    padding: 0 5%; }
    @media only screen and (max-width: 43.75em) {
      .promise__bottom {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: repeat(2, auto);
        grid-row-gap: 2rem; } }
    @media only screen and (max-width: 31.25em) {
      .promise__bottom {
        grid-template-columns: auto;
        grid-template-rows: repeat(4, auto);
        padding: 0 20%; } }
    .promise__bottom-list {
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.4; }
      .promise__bottom-list:not(:last-child) {
        margin-bottom: 2rem; }
    .promise__bottom-col1 {
      border: solid 2px #1B75BC;
      padding: 50px; }
    .promise__bottom-col2 {
      border: solid 2px green;
      padding: 50px; }
    .promise__bottom-col3 {
      border: solid 2px #F7941D;
      padding: 50px; }
    .promise__bottom-col4 {
      border: solid 2px #FF673E;
      padding: 50px; }

.getstarted {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 7 / 8;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 12rem;
  padding: 0 10%; }
  @media only screen and (max-width: 62.5em) {
    .getstarted {
      padding: 5% 10%; } }
  @media only screen and (max-width: 43.75em) {
    .getstarted {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      justify-items: center;
      grid-row-gap: 2rem; } }
  .getstarted__left {
    align-self: center;
    width: 80%; }
  .getstarted__right {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(5, auto);
    grid-column-gap: 6rem; }
    @media only screen and (max-width: 43.75em) {
      .getstarted__right {
        grid-template-columns: auto;
        grid-template-rows: repeat(8, auto);
        justify-items: center;
        grid-row-gap: 2rem; } }
    .getstarted__right-line {
      border-left: solid 4px #a6d1f2;
      height: 16rem;
      margin-left: 50%; }
      @media only screen and (max-width: 43.75em) {
        .getstarted__right-line {
          display: none; } }
    .getstarted__right-line2 {
      border-left: solid 4px #a6d1f2;
      height: 16rem;
      margin-left: 50%; }
      @media only screen and (max-width: 43.75em) {
        .getstarted__right-line2 {
          display: none; } }
    .getstarted__right-num {
      height: 7.5rem;
      width: 7.5rem;
      background-color: #a6d1f2;
      border-radius: 50%;
      display: block;
      text-align: center;
      color: #1B75BC;
      font-size: 5rem;
      text-anchor: middle;
      font-family: Open Sans;
      font-size: 3.6rem;
      padding-top: 10%; }
      @media only screen and (max-width: 43.75em) {
        .getstarted__right-num {
          padding-top: 1%; } }
    @media only screen and (max-width: 81.25em) {
      .getstarted__right-num1, .getstarted__right-body1 {
        transform: translate(0, 20%); } }
    @media only screen and (max-width: 62.5em) {
      .getstarted__right-num1, .getstarted__right-body1 {
        transform: translate(0, 50%); } }
    @media only screen and (max-width: 43.75em) {
      .getstarted__right-num1, .getstarted__right-body1 {
        transform: translate(0, 0); } }
  .getstarted__bottom {
    justify-self: center;
    transform: translateX(100%);
    padding-bottom: 25%; }
    @media only screen and (max-width: 43.75em) {
      .getstarted__bottom {
        transform: translateX(0);
        padding-top: 10%; } }

.loginmain {
  background-color: #FFFFFF;
  grid-column: 4 / 12;
  grid-row: 1 / 2;
  box-shadow: 0 2rem 3rem #6d6e71;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  .loginmain__left {
    display: grid;
    grid-template-rows: 30vh 70vh; }
    .loginmain__left-logo {
      width: 24.2rem;
      height: 16.3rem;
      margin-top: 10%; }
    .loginmain__left-top {
      align-self: center;
      justify-self: center; }
      .loginmain__left-top a:link,
      .loginmain__left-top a:active,
      .loginmain__left-top a:visited {
        outline: none;
        text-decoration: none;
        cursor: pointer; }
    .loginmain__left-bottom {
      justify-self: center; }
    .loginmain__left-text {
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      text-align: center;
      margin-bottom: 3rem; }
      .loginmain__left-text a:link,
      .loginmain__left-text a:active,
      .loginmain__left-text a:visited {
        outline: none;
        text-decoration: none;
        color: #111111;
        cursor: pointer; }
    .loginmain__left-input {
      display: block;
      width: 27.7rem;
      height: 4.6rem;
      border: 1px solid #7D7D7D;
      border-radius: 5px;
      font-size: 1.6rem;
      text-align: center; }
      .loginmain__left-input:not(:last-child) {
        margin-bottom: 1rem; }
      .loginmain__left-input:focus {
        outline: none; }
      .loginmain__left-input:focus:invalid {
        border: solid 1px #F7941D; }
      .loginmain__left-input:focus:valid {
        border: solid 1px green; }
      .loginmain__left-input::-webkit-input-placeholder {
        font-family: inherit;
        font-size: 1.6rem;
        color: rgba(17, 17, 17, 0.2); }
    .loginmain__left-btn1 {
      display: block;
      width: 27.7rem;
      height: 4.6rem;
      background-color: #1B75BC;
      color: #FFFFFF;
      border: none;
      outline: none;
      border-radius: 5px;
      font-size: 1.6rem;
      margin-bottom: 2rem;
      cursor: pointer; }
    .loginmain__left-btn2 {
      display: block;
      width: 27.7rem;
      height: 4.6rem;
      background-color: #FFFFFF;
      color: #1B75BC;
      border: 2px solid #1B75BC;
      outline: none;
      border-radius: 5px;
      font-size: 1.6rem;
      cursor: pointer; }
  .loginmain__right {
    background-image: url("/img/login_bkg.png");
    background-repeat: no-repeat;
    background-size: contain; }

.empty {
  background-color: #F5F5FC;
  grid-column: center-start / center-end;
  grid-row: 2 / 3; }

.signup {
  background-color: #FFFFFF;
  grid-column: 4 / 12;
  grid-row: 1 / 2;
  box-shadow: 0 2rem 3rem #6d6e71;
  padding-bottom: 5rem;
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center; }
  .signup__top {
    grid-row: 1 / 2; }
    .signup__top-image {
      width: 24.2rem;
      height: 16.3rem; }
    .signup__top a:link,
    .signup__top a:active,
    .signup__top a:visited {
      outline: none;
      text-decoration: none;
      cursor: pointer; }
  .signup__mid {
    grid-row: 2 / 3; }
    .signup__mid-btn {
      width: 21.2rem;
      height: 4.2rem;
      border: 1px solid #F7941D;
      outline: none;
      border-radius: 5px;
      background-color: #FFFFFF;
      font-family: inherit;
      font-size: 1.6rem;
      color: #F7941D;
      cursor: pointer; }
      .signup__mid-btn:not(:last-child) {
        margin-right: 1rem; }
    .signup__mid-btn:focus, .signup__mid-btn.active {
      width: 21.2rem;
      height: 4.2rem;
      border: none;
      outline: none;
      border-radius: 5px;
      background-color: #F7941D;
      font-family: inherit;
      font-size: 1.6rem;
      color: #FFFFFF;
      cursor: pointer; }
  .signup__bottom {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
    padding-bottom: 4rem;
    padding-top: 6rem; }
    .signup__bottom-text {
      font-family: inherit;
      font-size: 1.6rem;
      font-weight: 600;
      margin-bottom: 2rem; }
    .signup__bottom-smallinput {
      display: inline-block;
      width: 25rem;
      height: 5rem;
      border: 1px solid #979797;
      border-radius: 5px;
      margin-bottom: 2rem;
      padding-left: 1rem; }
      .signup__bottom-smallinput::-webkit-input-placeholder {
        font-size: 1.2rem;
        transform: translate(0, -90%);
        color: #111111; }
      .signup__bottom-smallinput:focus {
        outline: none; }
      .signup__bottom-smallinput:focus:invalid {
        border: solid 1px #F7941D; }
      .signup__bottom-smallinput:focus:valid {
        border: solid 1px green; }
      .signup__bottom-smallinput:not(:last-child) {
        margin-right: 1rem; }
    .signup__bottom-largeinput {
      display: block;
      width: 51.2rem;
      height: 5.2rem;
      border: 1px solid #979797;
      border-radius: 5px;
      margin-bottom: 2rem;
      padding-left: 1rem; }
      .signup__bottom-largeinput::-webkit-input-placeholder {
        font-size: 1.2rem;
        transform: translate(0, -90%);
        color: #111111; }
      .signup__bottom-largeinput:focus {
        outline: none; }
      .signup__bottom-largeinput:focus:invalid {
        border: solid 1px #F7941D; }
      .signup__bottom-largeinput:focus:valid {
        border: solid 1px green; }
    .signup__bottom-custominput {
      display: none; }
    .signup__bottom-label {
      display: inline-block;
      width: 145px;
      height: 34px;
      border-radius: 5px;
      background-color: #d8d8da;
      padding-top: 1.5%;
      padding-left: 10%;
      font-size: 1.2rem;
      outline: none; }
    .signup__bottom-text2 {
      font-size: 1.2rem;
      margin-bottom: 1rem; }
    .signup__bottom-textarea {
      width: 51.1rem;
      height: 7.5rem;
      margin-top: 2rem;
      border: 1px solid #979797;
      resize: none;
      border-radius: 5px;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      padding: 1rem; }
    .signup__bottom-checkbox {
      width: 2rem;
      height: 2rem;
      margin-right: 2rem;
      vertical-align: bottom; }
    .signup__bottom-agreelabel {
      font-family: inherit;
      font-size: 1.4rem;
      font-weight: 400; }
    .signup__bottom-button {
      width: 18.8rem;
      height: 4rem;
      border: none;
      border-radius: 5px;
      color: #FFFFFF;
      margin-left: calc(50% - 9.4rem); }

#business_owner {
  display: none; }

.empower {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  justify-items: center;
  padding: 5% 22%; }
  .empower__heading {
    text-align: center; }
  .empower__body {
    text-align: center;
    padding: 0 17%; }

.illustration {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  justify-items: center; }
  .illustration__box {
    padding: 0 10%; }
  .illustration__img {
    width: 100%; }

.ourstrategy {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  padding-top: 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 43.75em) {
    .ourstrategy {
      grid-template-columns: 1fr; } }
  .ourstrategy__left {
    justify-self: center; }
    @media only screen and (max-width: 43.75em) {
      .ourstrategy__left {
        padding-bottom: 3rem; } }
  .ourstrategy__right {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    padding-right: 10%; }
    @media only screen and (max-width: 43.75em) {
      .ourstrategy__right {
        padding-right: 0%;
        padding: 0 5%; } }
  .ourstrategy__body {
    margin-bottom: 6rem; }

.aboutdisplay {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  display: grid;
  grid-template-columns: repeat(2, 1fr); }
  @media only screen and (max-width: 43.75em) {
    .aboutdisplay {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto); } }
  .aboutdisplay__left-img {
    width: 100%;
    height: 100%;
    vertical-align: bottom; }
  .aboutdisplay__right {
    background-color: #fef3e6; }
    .aboutdisplay__right-body {
      font-family: inherit;
      font-size: 1.8rem;
      color: #C73A01;
      font-weight: 300;
      padding-top: 10%;
      padding-left: 20%;
      padding-right: 20%; }
    .aboutdisplay__right-body2 {
      font-family: inherit;
      font-size: 1.8rem;
      color: #C73A01;
      padding: 3rem 20%;
      text-align: center;
      font-weight: 300; }

.schedule {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  display: grid;
  justify-items: center;
  padding: 6% 0; }
  .schedule__text {
    font-family: Open Sans;
    font-size: 2.1rem;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 3rem; }

.trusted {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 7 / 8;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  padding-bottom: 10rem; }
  @media only screen and (max-width: 31.25em) {
    .trusted {
      grid-template-rows: repeat(2, auto); } }
  .trusted__top {
    justify-self: center;
    align-self: center; }
    @media only screen and (max-width: 31.25em) {
      .trusted__top {
        padding-bottom: 2rem; } }
    .trusted__top-text {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 700; }
  .trusted__bottom {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    align-items: end; }
    @media only screen and (max-width: 31.25em) {
      .trusted__bottom {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, auto);
        grid-row-gap: 4rem; } }
    .trusted__bottom-img {
      width: 14rem; }

.blogslide {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .blogslide {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .blogslide {
      grid-template-columns: 1fr;
      grid-template-rows: 20vh 1fr;
      padding-top: 0; } }
  .blogslide__text {
    padding-left: 15%;
    margin-top: 15%; }
    @media only screen and (max-width: 43.75em) {
      .blogslide__text {
        margin-top: 0;
        padding-left: 5%;
        padding-right: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .blogslide__img {
      justify-self: center; } }
  .blogslide__img img {
    width: 100%;
    height: auto;
    transform: translateY(-10%); }
    @media only screen and (max-width: 43.75em) {
      .blogslide__img img {
        height: 300px;
        width: auto; } }
    @media only screen and (max-width: 31.25em) {
      .blogslide__img img {
        height: 300px;
        width: 300px; } }

.blogdisplay {
  padding-top: 3%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .blogdisplay {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .blogdisplay {
      grid-template-columns: auto; } }
  .blogdisplay__imgbox {
    background-color: #FDF5E8; }
  .blogdisplay img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .blogdisplay p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .blogdisplay__header {
    margin-left: -4%;
    margin-bottom: 1.5rem; }
  .blogdisplay a:link,
  .blogdisplay a:active,
  .blogdisplay a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }
  .blogdisplay h4 {
    max-width: 340px; }
    @media only screen and (max-width: 87.5em) {
      .blogdisplay h4 {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .blogdisplay h4 {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .blogdisplay h4 {
        max-width: 200px; } }

.moreinfo {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }
  @media only screen and (max-width: 43.75em) {
    .moreinfo img {
      width: 50px; } }

.blogmain {
  padding-top: 7%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-rows: repeat(6, auto);
  justify-items: center; }
  .blogmain__header {
    grid-row: 1 / 2;
    max-width: 90%;
    padding: 0 10%;
    margin-bottom: 6rem; }
  .blogmain__author {
    grid-row: 2 / 3; }
  .blogmain__date {
    grid-row: 3 / 4;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: Open Sans;
    color: #8A8A8A;
    margin-bottom: 4rem; }
  .blogmain__image {
    grid-row: 4 / 5;
    width: 70%;
    height: auto;
    margin-bottom: 5rem; }
  .blogmain__text {
    grid-row: 5 / 6;
    padding-left: 15%;
    padding-right: 15%; }
  .blogmain__footer {
    grid-row: 6 / 7;
    justify-self: start;
    padding-left: 15%;
    margin-bottom: 8rem;
    display: grid;
    grid-template-columns: 1fr; }
    .blogmain__footer-text {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 400;
      margin-bottom: 4rem;
      margin-top: 3rem; }
    .blogmain__footer-categories {
      color: #6D6E71;
      margin-bottom: 4rem; }
    .blogmain__footer-items {
      display: grid;
      grid-template-columns: repeat(3, max-content);
      grid-column-gap: 2rem; }

.related {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-rows: auto max-content;
  grid-row-gap: 5rem; }
  .related__posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    grid-row-gap: 6rem;
    padding-left: 1%;
    margin-bottom: 10rem; }
    @media only screen and (max-width: 43.75em) {
      .related__posts {
        grid-template-columns: auto; } }
  .related__heading {
    justify-self: center;
    font-size: 1.8rem;
    font-family: Open Sans;
    color: #1B75BC; }
  .related__imgbox {
    background-color: #FDF5E8; }
  .related img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .related p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .related__header {
    margin-left: -4%;
    max-width: 300px; }
  .related a:link,
  .related a:active,
  .related a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }

.vaheader {
  background-color: #1B4E5D;
  grid-column: center-start / center-end;
  grid-row: 1 / 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 30% 70%;
  align-items: top; }
  .vaheader__top {
    padding: 3% 3%;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 30% 60% 10%;
    align-items: center; }
    .vaheader__top-pic {
      width: 5rem;
      height: 5rem;
      justify-self: center;
      border: solid 1px #FFFFFF;
      border-radius: 50%; }
    .vaheader__top:link, .vaheader__top:visited {
      text-decoration: none;
      outline: none;
      cursor: pointer; }
  .vaheader__bottom {
    grid-row: 2 / 3;
    padding: 3% 15%; }
  .vaheader__header {
    color: #FFFFFF; }
  .vaheader__caption {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 1.8rem;
    color: #FFFFFF; }

.vaheaderlogo {
  grid-column: 1fr;
  grid-row: 1 / 2; }

.vamain {
  background-color: #F1F1F1;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  padding-bottom: 15rem; }
  .vamain__top {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: 4rem;
    transform: translate(6%, -25%); }
  .vamain__bottom {
    display: grid;
    grid-template-columns: 1fr;
    transform: translate(6%, -15%); }
  .vamain__left {
    display: grid;
    grid-template-rows: 70% 30%; }
  .vamain__project {
    background-color: #FFFFFF;
    width: 78.2rem;
    height: 46.4rem;
    border-radius: 10px;
    display: grid;
    grid-template-columns: 30% 70%;
    padding: 2rem 4rem; }
    .vamain__project-right {
      display: grid;
      grid-template-rows: repeat(4, auto);
      padding-bottom: 2rem; }
    .vamain__project-header {
      font-family: Libre Baskerville, serif;
      font-size: 2.4rem;
      font-weight: 400;
      max-width: 60%;
      color: #1B4E5D;
      line-height: 2; }
    .vamain__project-tasks {
      list-style: none;
      padding-left: 4rem;
      display: grid;
      grid-template-columns: repeat(3, auto);
      align-items: center; }
      .vamain__project-tasks a:link,
      .vamain__project-tasks a:active,
      .vamain__project-tasks a:visited {
        text-decoration: none;
        color: #1B4E5D;
        font-family: Open Sans;
        font-size: 1.6rem; }
    .vamain__project-upcomingtasks {
      list-style: none;
      padding-left: 4rem;
      display: grid;
      grid-template-columns: repeat(3, auto);
      align-items: center; }
      .vamain__project-upcomingtasks a:link,
      .vamain__project-upcomingtasks a:active,
      .vamain__project-upcomingtasks a:visited {
        text-decoration: none;
        color: #1B4E5D;
        font-family: Open Sans;
        font-size: 1.6rem; }
    .vamain__project-dates {
      color: #1B4E5D;
      font-family: Open Sans;
      font-size: 1.4rem;
      justify-self: end; }
    .vamain__project-dates2 {
      color: #D50000;
      font-family: Open Sans;
      font-size: 1.4rem;
      justify-self: end; }
    .vamain__project-top {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center;
      padding-left: 4rem; }
    .vamain__project-upcoming {
      display: grid;
      grid-template-columns: repeat(2, auto);
      align-items: center;
      padding-left: 4rem; }
    .vamain__project-topheader {
      font-family: Open Sans;
      font-size: 1.8rem;
      font-weight: 400; }
    .vamain__project-button {
      width: 13.4rem;
      height: 3.4rem;
      border: none;
      border-radius: 50px;
      background-color: #1B4E5D;
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 400;
      cursor: pointer;
      outline: none;
      margin-bottom: 4rem;
      margin-top: 5rem; }
  .vamain__inbox {
    background-color: #FFFFFF;
    width: 45rem;
    height: 46.4rem;
    border-radius: 10px;
    padding: 4rem 6rem;
    display: grid;
    grid-template-rows: repeat(3, auto); }
    .vamain__inbox-top {
      display: grid;
      grid-template-columns: 70% 30%; }
    .vamain__inbox-right {
      justify-self: end;
      transform: translate(0, -10%); }
    .vamain__inbox-heading {
      font-family: Open Sans;
      font-size: 1.8rem;
      text-decoration: underline;
      color: #1B4E5D;
      font-weight: 400; }
    .vamain__inbox-arrow:not(:last-child) {
      margin-right: 2rem; }
    .vamain__inbox-main {
      font-family: Open Sans;
      font-size: 2.1rem;
      color: #1B4E5D;
      font-weight: 400; }
    .vamain__inbox-end {
      display: grid;
      grid-template-columns: repeat(2, auto); }
    .vamain__inbox-mailicon {
      width: 167px;
      height: 152px; }
    .vamain__inbox-button {
      width: 15rem;
      height: 3.4rem;
      border: none;
      border-radius: 50px;
      background-color: #1B4E5D;
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 400;
      cursor: pointer;
      outline: none;
      margin-bottom: 4rem;
      margin-top: 5rem; }
    .vamain__inbox-count {
      font-family: Open Sans;
      color: #1B4E5D;
      font-size: 1.8rem;
      font-weight: 400; }
    .vamain__inbox-count2 {
      color: rgba(27, 78, 93, 0.5); }
  .vamain__calendar {
    background-color: #FFFFFF;
    width: 78.2rem;
    height: 46.4rem;
    border-radius: 10px;
    display: grid;
    align-items: center;
    justify-items: center; }
  .vamain__left {
    border-right: solid 1px #6D6E71; }

.vafooter {
  background-color: #6C7F8E;
  grid-column: center-start / center-end;
  display: grid;
  grid-template-columns: 40% 0 60%;
  padding-top: 4rem;
  margin-bottom: 10rem; }
  .vafooter__contact {
    grid-column: 1 / 2;
    display: grid;
    padding-left: 5rem;
    grid-template-columns: 40% 1fr; }
    .vafooter__contact a:link,
    .vafooter__contact a:active,
    .vafooter__contact a:visited {
      outline: none; }
    .vafooter__contact-logo {
      width: 12rem;
      border: 0; }
    .vafooter__contact--info {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto); }
      .vafooter__contact--info-header {
        color: #FFFFFF;
        font-size: 1.6rem;
        font-weight: 400;
        align-self: end; }
      .vafooter__contact--info-text {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF;
        align-self: center; }
      .vafooter__contact--info-phone {
        font-size: 1.3rem;
        font-weight: 300;
        color: #FFFFFF; }
  .vafooter__nav {
    grid-column: 3 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto); }
    .vafooter__nav-nav {
      list-style: none;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
      justify-items: center;
      align-items: center;
      margin-bottom: 4rem; }
      .vafooter__nav-nav a:link,
      .vafooter__nav-nav a:visited {
        color: #FFFFFF;
        text-decoration: none;
        font-size: 1.3rem;
        font-weight: 600; }
    .vafooter__nav-copy {
      color: #FFFFFF;
      font-size: 1.6rem;
      font-weight: 400;
      padding-right: 6%;
      justify-self: end;
      align-self: center; }

.consult {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-top: 6%; }
  .consult__header {
    text-align: center;
    padding-bottom: 3%; }

.contactinfo {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  padding-bottom: 5%;
  grid-row-gap: 4rem; }
  .contactinfo__body {
    font-family: Open Sans;
    font-weight: 400;
    color: #111111;
    font-size: 1.6rem;
    max-width: 60%; }
  .contactinfo a:link,
  .contactinfo a:active,
  .contactinfo a:visited {
    outline: none; }

.mailinglist {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 8 / 9;
  display: grid;
  grid-template-rows: repeat(3, auto);
  justify-items: center;
  grid-row-gap: 3rem;
  padding: 6rem 0; }
  .mailinglist__top {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 3rem; }
  .mailinglist__icon {
    width: 3.7rem;
    height: 3.7rem; }
  .mailinglist__header {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #111111; }
  .mailinglist__body {
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.4rem;
    color: #111111;
    padding: 0 27%;
    text-align: center; }
  .mailinglist__textbox {
    width: 60rem;
    height: 4rem;
    border: none;
    border-bottom: solid 1px #C1C1C1;
    outline: none;
    font-weight: 300;
    margin-right: 2rem;
    padding-left: 30%; }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__textbox {
        margin-bottom: 4rem; } }
    @media only screen and (max-width: 31.25em) {
      .mailinglist__textbox {
        width: 40rem;
        padding-left: 0%; } }
    @media only screen and (max-width: 25em) {
      .mailinglist__textbox {
        width: 300px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem; } }
    .mailinglist__textbox::-webkit-input-placeholder {
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      color: #C1C1C1; }
    .mailinglist__textbox:focus {
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      color: #111111;
      padding-left: 30%; }
      @media only screen and (max-width: 31.25em) {
        .mailinglist__textbox:focus {
          padding-left: 0%; } }
    .mailinglist__textbox:focus:invalid {
      border-bottom: solid 1px #F7941D; }
    .mailinglist__textbox:focus:valid {
      border-bottom: solid 1px green; }
  .mailinglist__button {
    width: 15.8rem;
    height: 3.5rem;
    font-family: Open Sans;
    font-size: 1.4rem;
    color: #FFFFFF;
    background-color: #1B75BC;
    outline: none;
    cursor: pointer;
    border: none;
    border-radius: 50px; }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__button {
        justify-self: center; } }
    @media only screen and (max-width: 25em) {
      .mailinglist__button {
        margin-left: 0; } }
  .mailinglist__bottom {
    display: grid;
    grid-template-columns: repeat(2, auto);
    transform: translate(13%, 0); }
    @media only screen and (max-width: 50em) {
      .mailinglist__bottom {
        transform: translate(0, 0); } }
    @media only screen and (max-width: 43.75em) {
      .mailinglist__bottom {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: repeat(2, auto);
        transform: translate(0, 0); } }

.messageframe {
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-auto-columns: 1fr; }
  .messageframe__subjectsec {
    display: grid;
    grid-template-columns: 70% 25% 5%;
    padding: 5%; }
  .messageframe__msgbody {
    font-family: Open Sans;
    font-size: 2.2rem;
    font-weight: 300;
    color: #545454;
    padding: 0 5%; }
  .messageframe__row1 {
    border: 1px solid black;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: 79% repeat(3, 7%);
    grid-template-rows: 11.3rem;
    align-items: center;
    border: solid 1px rgba(27, 78, 93, 0.15); }
    .messageframe__row1 a:link,
    .messageframe__row1 a:active,
    .messageframe__row1 a:visited {
      text-decoration: none;
      outline: none; }
    .messageframe__row1-input {
      height: 7.3rem;
      width: 95%;
      padding-left: 15%;
      border: none;
      outline: none;
      font-size: 2.6rem; }
      .messageframe__row1-input::-webkit-input-placeholder {
        font-family: Open Sans;
        font-size: 2.6rem;
        font-weight: 300;
        color: #A3A3A3; }
      .messageframe__row1-input:focus {
        font-family: Open Sans;
        font-size: 2.6rem;
        font-weight: 400;
        color: #A3A3A3;
        padding-left: 10%; }
      .messageframe__row1-input:focus:invalid {
        border-bottom: solid 1px #F7941D; }
      .messageframe__row1-input:focus:valid {
        border-bottom: solid 1px green; }
    .messageframe__row1-searchimg {
      transform: translate(100%, 170%); }
  .messageframe__row2 {
    grid-row: 2 / 3;
    display: grid;
    grid-template-rows: repeat(2, auto);
    border: solid 1px rgba(27, 78, 93, 0.15);
    padding-bottom: 15rem; }
    .messageframe__row2-col1 {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 3.1rem;
      color: #545454; }
    .messageframe__row2-col2a {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 2.5rem;
      color: #545454;
      padding-left: 45%; }
    .messageframe__row2-col2b {
      font-family: Open Sans;
      font-weight: 300;
      font-size: 2rem;
      color: #8A8A8A; }
    .messageframe__row2-col3 {
      width: 50px; }
  .messageframe__row3 {
    grid-row: 3 / 4;
    display: grid;
    grid-template-rows: repeat(2, auto);
    border: solid 1px rgba(27, 78, 93, 0.15); }
    .messageframe__row3-header {
      font-family: Open Sans;
      font-weight: 600;
      font-size: 2.5rem;
      color: #1B4E5D;
      padding: 5%; }
    .messageframe__row3-bottom {
      display: grid;
      grid-template-rows: repeat(2, auto); }
    .messageframe__row3-text {
      font-family: Open Sans;
      font-weight: 300;
      font-size: 2rem;
      color: #545454;
      width: 90%;
      height: 50rem;
      margin: 0 5%;
      border: none;
      resize: none;
      outline: none; }
      .messageframe__row3-text:-webkit-input-placeholder {
        font-family: Open Sans;
        font-weight: 300;
        font-size: 1.5rem;
        color: #C0C0C0; }
    .messageframe__row3-button {
      border-radius: 50px;
      font-family: Open Sans;
      font-size: 2.8rem;
      font-weight: 400;
      color: #FFFFFF;
      border: none;
      cursor: pointer;
      outline: none;
      width: 16.1rem;
      height: 5.4rem;
      background-color: #1B4E5D; }
    .messageframe__row3-btnsection {
      border: solid 1px rgba(27, 78, 93, 0.15);
      padding: 2% 5%;
      display: grid;
      justify-items: end; }

.messagegframebody {
  background-color: #FFFFFF; }

.messagebody {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: 42% 58%;
  /*
    &__col2 {
        height: 7.5rem;
        display: grid;
 

        &-sec1 {
            display: grid;
            grid-template-columns: 79% repeat(3, 7%);
            align-items: center;
            border: solid 1px rgba($color: $color-va-header, $alpha: 0.15);

            a:link,
            a:active,
            a:visited {
                text-decoration: none;
                outline: none;
            }
        }

        &-input {
            height: 7.3rem;
            width: 95%;
            padding-left: 10%;
            border: none;
            outline: none;
            font-size: 1.6rem;

            &::-webkit-input-placeholder {
                font-family: $font-primary;
                font-size: 1.6rem;
                font-weight: 400;
                color: #A3A3A3;
            }

            &:focus {
                font-family: $font-primary;
                font-size: 1.6rem;
                font-weight: 400;
                color: #A3A3A3;
                padding-left: 10%;
              
            }

            &:focus:invalid {
                border-bottom: solid 1px $color-orange;
            }
    
            &:focus:valid {
                border-bottom: solid 1px green;
            }
        }

        &-icon1 {
            width: 2.5rem;
            height: 1.9rem;
            transform: translate(120%, 0);
        }
    }
    */ }
  .messagebody__col1 {
    overflow-y: scroll !important; }
    .messagebody__col1 a:link,
    .messagebody__col1 a:active,
    .messagebody__col1 a:visited {
      text-decoration: none;
      outline: none; }
    .messagebody__col1-top {
      height: 75px;
      border: solid 1px rgba(27, 78, 93, 0.15);
      background-color: rgba(27, 78, 93, 0.1);
      padding: 5%; }
    .messagebody__col1-header {
      font-family: Open Sans;
      font-size: 1.6rem;
      font-weight: 400;
      color: #1B4E5D;
      padding-left: 5%;
      padding-right: 5%; }
    .messagebody__col1-bottom {
      display: grid; }
    .messagebody__col1-body {
      display: grid;
      grid-template-columns: 10% 65% repeat(2, 10%);
      grid-column-gap: 2rem;
      border: solid 1px rgba(27, 78, 93, 0.15);
      padding: 5%; }
    .messagebody__col1-icon {
      width: 50px; }
    .messagebody__col1-from {
      font-family: Open Sans;
      font-size: 1.6rem;
      font-weight: 400;
      color: rgba(27, 78, 93, 0.6); }
    .messagebody__col1-subject {
      font-family: Open Sans;
      font-size: 1.6rem;
      font-weight: 400;
      color: #545454; }
    .messagebody__col1-excerpt {
      font-family: Open Sans;
      font-size: 1.4rem;
      font-weight: 300;
      color: #545454;
      display: -webkit-box;
      margin: 0 auto;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis; }
    .messagebody__col1-date {
      font-family: Open Sans;
      font-size: 1.2rem;
      font-weight: 300;
      color: rgba(27, 78, 93, 0.6); }

.active {
  background-color: rgba(27, 78, 93, 0.05); }

#seeMore {
  display: none; }

#seeMore:target {
  display: block; }

.imessageframe {
  width: 100%;
  height: 100%;
  border: none;
  background-color: #FFFFFF; }

.navigation {
  display: none; }
  @media only screen and (max-width: 43.75em) {
    .navigation {
      display: block; } }
  .navigation__checkbox {
    display: none; }
  .navigation__button {
    background-color: #fbd19d;
    height: 7rem;
    width: 7rem;
    position: fixed;
    top: 9rem;
    right: 9%;
    border-radius: 50%;
    z-index: 2000;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 1rem 3rem rgba(17, 17, 17, 0.1); }
  .navigation__background {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    position: fixed;
    top: 9.5rem;
    right: 9%;
    z-index: 1000;
    background-image: radial-gradient(#F7941D, #fbd19d);
    transition: transform .8s; }
  .navigation__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1500;
    width: 0;
    transition: all .8s;
    visibility: hidden; }
  .navigation__list {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    text-align: center;
    z-index: 1800; }
  .navigation__item {
    margin: 1rem; }
  .navigation__link {
    font-size: 3rem;
    font-weight: 300;
    color: #FFFFFF;
    text-decoration: none; }
  .navigation__checkbox:checked ~ .navigation__background {
    transform: scale(80); }
  .navigation__checkbox:checked ~ .navigation__nav {
    visibility: visible; }
  .navigation__icon {
    position: relative;
    margin-top: 3.5rem; }
    .navigation__icon, .navigation__icon::before, .navigation__icon::after {
      width: 3rem;
      height: 2px;
      background-color: #111111;
      display: inline-block; }
    .navigation__icon::before, .navigation__icon::after {
      content: "";
      position: absolute;
      left: 0; }
    .navigation__icon::before {
      top: -.8em; }
    .navigation__icon::after {
      top: .8rem; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent; }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::before {
    top: 0;
    transform: rotate(45deg); }
  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-45deg); }

.msgpopup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  .msgpopup:target {
    opacity: 1;
    visibility: visible; }
  .msgpopup__content {
    width: 63.3rem;
    height: 43.4rem;
    border-radius: 10px;
    padding: 2%;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-rows: repeat(2, auto);
    box-shadow: 0 2rem 3rem rgba(17, 17, 17, 0.5); }
  .msgpopup__header {
    font-family: Libre Baskerville, serif;
    font-weight: 700;
    font-size: 2.4rem;
    color: #1B4E5D;
    padding-bottom: 4rem; }
  .msgpopup__text {
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.6rem;
    color: #1B4E5D; }
  .msgpopup__footer {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 1.6rem;
    color: #1B4E5D; }
  .msgpopup__date {
    font-family: Open Sans;
    font-weight: 400;
    font-size: 1.4rem;
    color: #1B4E5D;
    font-style: italic;
    transform: translate(80%, -80%); }
  .msgpopup__close:link, .msgpopup__close:visited {
    color: #1B4E5D;
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    font-size: 3rem;
    text-decoration: none;
    display: inline-block; }

.profilepopup {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  .profilepopup:target {
    opacity: 1;
    visibility: visible; }
  .profilepopup__content {
    width: 73.7rem;
    height: 101.7rem;
    border-radius: 10px;
    background-color: #FFFFFF;
    position: absolute;
    left: 43%;
    top: 10%;
    display: grid;
    grid-template-rows: max-content repeat(2, auto) max-content;
    box-shadow: 0 2rem 3rem rgba(17, 17, 17, 0.5); }
  .profilepopup__photo {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: repeat(3, auto);
    border-bottom-style: solid;
    border-bottom-color: #DEDEDE;
    border-bottom-width: 1px;
    padding: 3%; }
  .profilepopup__img {
    width: 13.7rem;
    height: 13.7rem;
    border-radius: 50%;
    border: 1px solid #1B4E5D;
    justify-self: center;
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .profilepopup__close:link, .profilepopup__close:visited {
    font-family: Open Sans;
    font-size: 1.8rem;
    color: #848484;
    font-weight: 400;
    text-decoration: none; }
  .profilepopup__edit {
    justify-self: end; }
    .profilepopup__edit:link, .profilepopup__edit:visited {
      font-family: Open Sans;
      font-size: 1.8rem;
      color: #4099D9;
      font-weight: 400;
      text-decoration: none; }
  .profilepopup__personal {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(5, auto);
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 2%;
    padding-bottom: 2rem;
    align-items: end;
    border-bottom-style: solid;
    border-bottom-color: #DEDEDE;
    border-bottom-width: 1px; }
  .profilepopup__account {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(4, auto);
    padding-top: 2%;
    padding-left: 10%;
    padding-right: 2%;
    padding-bottom: 2rem;
    align-items: end; }
  .profilepopup__static {
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.6rem;
    color: #3F3F3F; }
  .profilepopup__dynamic {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 1.6rem;
    color: #3F3F3F;
    border-bottom-style: solid;
    border-bottom-color: #DEDEDE;
    border-bottom-width: 1px;
    margin-right: 2%;
    padding-left: 4rem; }
  .profilepopup__dynamic2 {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 1.6rem;
    color: #3F3F3F;
    margin-right: 2%;
    padding-left: 4rem; }
  .profilepopup__logout {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    padding-left: 4rem;
    padding: 5% 10%; }
  .profilepopup__header {
    font-family: Open Sans;
    font-weight: 600;
    font-size: 1.8rem;
    color: #3F3F3F;
    margin-right: 2%;
    padding-left: 4rem; }
  .profilepopup__exit:link, .profilepopup__exit:visited {
    text-decoration: none;
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.8rem;
    color: #4099D9; }

.demopopup {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(17, 17, 17, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all .3s; }
  .demopopup:target {
    opacity: 1;
    visibility: visible; }
  .demopopup__content {
    width: 80%;
    height: 80vh;
    border-radius: 10px;
    padding: 0;
    background-color: #FFFFFF;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    display: grid;
    grid-template-rows: repeat(2, auto);
    box-shadow: 0 2rem 3rem rgba(17, 17, 17, 0.5); }
  .demopopup__video {
    width: 100%;
    height: 80vh; }
  .demopopup__close:link, .demopopup__close:visited {
    color: #FFFFFF;
    position: absolute;
    top: 2.5rem;
    right: 12.5rem;
    font-size: 5rem;
    text-decoration: none;
    display: inline-block;
    cursor: pointer; }
    @media only screen and (max-width: 43.75em) {
      .demopopup__close:link, .demopopup__close:visited {
        right: 50%; } }

.vaprofile {
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: repeat(2, auto); }
  .vaprofile__photo {
    max-width: 50%;
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-row-gap: 2rem;
    padding-left: 8%;
    padding-top: 5%;
    padding-bottom: 5%; }
  .vaprofile__header {
    font-family: Open Sans;
    font-size: 2.4rem;
    color: #1B4E5D;
    font-weight: 400; }
  .vaprofile__photoinfo {
    display: grid;
    grid-template-columns: repeat(2, auto); }
    .vaprofile__photoinfo-right {
      align-self: center; }
  .vaprofile__propic {
    width: 13.5rem;
    height: 13.5rem;
    border: 1px solid #1B4E5D;
    border-radius: 50%; }
  .vaprofile__input {
    display: none; }
  .vaprofile__label {
    display: inline-block;
    width: 16.3rem;
    height: 3.4rem;
    border-radius: 5px;
    background-color: #A5A5A5;
    padding-top: 1%;
    padding-left: 8%;
    font-size: 1.6rem;
    outline: none;
    font-weight: 400;
    color: #FFFFFF; }
  .vaprofile__btn1 {
    border-radius: 5px;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
    width: 13.3rem;
    height: 3.4rem; }
  .vaprofile__btn2 {
    border-radius: 50px;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    background-color: #1B4E5D;
    border: none;
    cursor: pointer;
    outline: none;
    width: 11.1rem;
    height: 3.4rem;
    color: #FFFFFF;
    margin-top: 20%;
    float: right;
    margin-right: 30%; }
  .vaprofile__info {
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding-bottom: 10rem;
    padding-left: 5%; }
  .vaprofile__input2 {
    display: block; }
  .vaprofile__label2 {
    font-family: Open Sans;
    font-size: 1.6rem;
    font-weight: 400; }
  .vaprofile__input2 {
    width: 52.5rem;
    height: 4.6rem;
    border-radius: 5px;
    outline: none;
    border: 1px solid #BFBFBF;
    padding-left: 3%;
    font-size: 1.6rem;
    color: #111111;
    font-weight: 300; }
    .vaprofile__input2:not(:last-child) {
      margin-bottom: 2rem; }
    .vaprofile__input2::-webkit-input-placeholder {
      font-size: 1.6rem;
      color: #111111; }
    .vaprofile__input2:focus {
      outline: none; }
    .vaprofile__input2:focus:invalid {
      border: solid 1px #F7941D; }
    .vaprofile__input2:focus:valid {
      border: solid 1px green; }
  .vaprofile__basic {
    padding-left: 8%; }
  .vaprofile__listitem {
    column-count: 3;
    column-gap: 1rem;
    padding: 2rem 0; }
    .vaprofile__listitem li {
      font-size: 1.4rem;
      color: #5C5C5C;
      background: url("https://thehragentadmin.com/img/liststyle.png") no-repeat left 5px;
      padding-left: 3rem;
      list-style: none; }

.vaprogress {
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  background-color: #FFFFFF;
  display: grid;
  grid-template-rows: max-content 1fr; }
  .vaprogress__top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 3% 5%;
    border-bottom: 2px solid rgba(27, 78, 93, 0.1); }
  .vaprogress__topleft {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    grid-column-gap: 4rem; }
  .vaprogress__topright {
    display: grid;
    grid-template-columns: 1fr repeat(3, auto);
    grid-column-gap: 2rem;
    align-items: center;
    justify-items: center; }
  .vaprogress__avatar {
    width: 5rem;
    height: 5rem;
    border: 1px solid #1B4E5D;
    border-radius: 50%; }
  .vaprogress__header1 {
    font-family: inherit;
    font-size: 2.1rem;
    font-weight: 400;
    color: #1B4E5D; }
  .vaprogress__search {
    width: 20.3rem;
    height: 3.5rem;
    border: 1px solid #D2D2D2;
    border-radius: 50px;
    outline: none;
    padding-left: 6rem;
    font-size: 1.6rem;
    font-weight: 400;
    background: url("https://www.thehragentadmin.com/img/iconsearch.png") top left no-repeat;
    background-position: 5% 50%; }
    .vaprogress__search:focus {
      width: 30.3rem; }
  .vaprogress__taskbutton {
    border-radius: 50px;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFFFFF;
    border: none;
    cursor: pointer;
    outline: none;
    width: 13.9rem;
    height: 3.5rem; }
  .vaprogress__select {
    border: none;
    appearance: field;
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.6rem;
    color: #1B4E5D;
    outline: none; }
  .vaprogress__dropdown1 {
    position: relative;
    display: inline-block;
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.6rem;
    color: #1B4E5D;
    cursor: pointer; }
    .vaprogress__dropdown1-content {
      position: absolute;
      background-color: #FFFFFF;
      min-width: 16rem;
      box-shadow: 0 2rem 3rem #6d6e71;
      z-index: 1;
      padding: 12px 16px;
      border-radius: 5px;
      display: none;
      transform: translate(-25%, 0%); }
      .vaprogress__dropdown1-content a:link,
      .vaprogress__dropdown1-content a:active,
      .vaprogress__dropdown1-content a:visited {
        outline: none;
        text-decoration: none;
        cursor: pointer;
        color: #1B4E5D; }
      .vaprogress__dropdown1-content li {
        list-style: none; }
        .vaprogress__dropdown1-content li:not(:last-child) {
          padding-bottom: 1rem; }
      .vaprogress__dropdown1-content li:before {
        content: ' \2713';
        display: none; }
    .vaprogress__dropdown1:hover .vaprogress__dropdown1-content {
      display: block; }
  .vaprogress__dropdown2 {
    position: relative;
    display: inline-block;
    font-family: Open Sans;
    font-weight: 300;
    font-size: 1.6rem;
    color: #1B4E5D;
    cursor: pointer; }
    .vaprogress__dropdown2-content {
      position: absolute;
      background-color: #FFFFFF;
      min-width: 10rem;
      box-shadow: 0 2rem 3rem #6d6e71;
      z-index: 1;
      padding: 12px 16px;
      border-radius: 5px;
      display: none;
      transform: translate(0%, 0%); }
      .vaprogress__dropdown2-content a:link,
      .vaprogress__dropdown2-content a:active,
      .vaprogress__dropdown2-content a:visited {
        outline: none;
        text-decoration: none;
        cursor: pointer;
        color: #1B4E5D; }
      .vaprogress__dropdown2-content li {
        list-style: none; }
        .vaprogress__dropdown2-content li:not(:last-child) {
          padding-bottom: 1rem; }
    .vaprogress__dropdown2:hover .vaprogress__dropdown2-content {
      display: block; }
  .vaprogress__bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr); }
  .vaprogress__left {
    border-right: 1px solid rgba(27, 78, 93, 0.1); }

.blogsheader {
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  background-color: #FFFFFF;
  padding: 2% 5%; }
  .blogsheader div {
    background-color: #FDF5E8;
    display: inline-block; }
  .blogsheader__heading {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #1B75BC;
    transform: translate(-10%, 6%); }

.ebookdisplay {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 7 / 8;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .ebookdisplay {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .ebookdisplay {
      grid-template-columns: auto; } }
  .ebookdisplay__img {
    width: 29.2rem;
    height: 41.0rem; }
  .ebookdisplay__date {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%;
    padding-bottom: 1rem; }
  .ebookdisplay__title {
    max-width: 340px;
    margin-left: -4%;
    padding-bottom: 1rem; }
    @media only screen and (max-width: 87.5em) {
      .ebookdisplay__title {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .ebookdisplay__title {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .ebookdisplay__title {
        max-width: 200px; } }
  .ebookdisplay a:link,
  .ebookdisplay a:active,
  .ebookdisplay a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }

.moreinfo2 {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 8 / 9;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }
  @media only screen and (max-width: 43.75em) {
    .moreinfo2 img {
      width: 50px; } }

.ebookheader {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 6 / 7;
  padding: 2% 5%; }
  .ebookheader div {
    background-color: #FDF5E8;
    display: inline-block; }
  .ebookheader__heading {
    font-family: Open Sans;
    font-weight: 700;
    font-size: 2.4rem;
    color: #1B75BC;
    transform: translate(-10%, 6%); }

.blogslideall {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 1fr));
  grid-row: auto; }
  @media only screen and (max-width: 87.5em) {
    .blogslideall {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 43.75em) {
    .blogslideall {
      grid-template-columns: 1fr;
      grid-template-rows: 20vh 1fr;
      padding-top: 0; } }
  .blogslideall__text {
    padding-left: 15%;
    margin-top: 15%; }
    @media only screen and (max-width: 43.75em) {
      .blogslideall__text {
        margin-top: 0;
        padding-left: 5%;
        padding-right: 5%; } }
  @media only screen and (max-width: 43.75em) {
    .blogslideall__img {
      justify-self: center; } }
  .blogslideall__img img {
    width: 100%;
    height: auto;
    transform: translateY(-10%); }
    @media only screen and (max-width: 43.75em) {
      .blogslideall__img img {
        height: 300px;
        width: auto; } }
    @media only screen and (max-width: 31.25em) {
      .blogslideall__img img {
        height: 300px;
        width: 300px; } }

.blogdisplayall {
  padding-top: 3%;
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 3 / 4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  grid-row-gap: 6rem;
  padding-left: 1%; }
  @media only screen and (max-width: 43.75em) {
    .blogdisplayall {
      grid-template-columns: repeat(2, 1fr); } }
  @media only screen and (max-width: 31.25em) {
    .blogdisplayall {
      grid-template-columns: auto; } }
  .blogdisplayall__imgbox {
    background-color: #FDF5E8; }
  .blogdisplayall img {
    width: 34.4rem;
    height: 24.3rem;
    transform: translate(-4%, 6%); }
  .blogdisplayall p {
    padding-top: 6%;
    font-family: inherit;
    font-size: 1.4rem;
    color: #F7941D;
    font-weight: 300;
    margin-left: -4%; }
  .blogdisplayall__header {
    margin-left: -4%;
    margin-bottom: 1.5rem; }
  .blogdisplayall a:link,
  .blogdisplayall a:active,
  .blogdisplayall a:visited {
    margin-left: -4%;
    font-family: Open Sans;
    font-size: 1.4rem;
    font-weight: 400;
    color: #1B75BC; }
  .blogdisplayall h4 {
    max-width: 340px; }
    @media only screen and (max-width: 87.5em) {
      .blogdisplayall h4 {
        max-width: 400px; } }
    @media only screen and (max-width: 68.75em) {
      .blogdisplayall h4 {
        max-width: 300px;
        font-size: 1.5rem; } }
    @media only screen and (max-width: 43.75em) {
      .blogdisplayall h4 {
        max-width: 200px; } }

.moreinfoall {
  background-color: #FFFFFF;
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  display: grid;
  justify-items: center;
  grid-row-gap: 10rem;
  padding: 8%; }
  @media only screen and (max-width: 43.75em) {
    .moreinfoall img {
      width: 50px; } }
